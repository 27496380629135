/* You can add global styles to this file, and also import other style files */
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700;300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&family=Open+Sans:wght@300;500&display=swap');
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
// @import '~@ctrl/ngx-emoji-mart/picker';



body {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500;
    background-color: #F2F2F2 !important;
}

.main-container {
    background: transparent !important;
    // background: url('assets/images/shutterstock_1939824820.jpg') no-repeat center center fixed;
    background-size: cover;
    height: 100vh;
}

.h-100 {
    height: 100vh !important;
    overflow: hidden;
}

.btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
}

.btn-toggle[aria-expanded="true"] {
    color: rgba(255, 255, 255, .85);
}

.btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: 0.5em 50%;
}

h1 {
    font-size: 3em;
    font-weight: 700;
}

h3 {
    font-size: 21px;
    font-weight: 700;
}

h5 {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: rgba(99, 99, 99, 1.0);
    letter-spacing: 1px;
}

p {
    font-size: 24px;
}

.btn-success {
    background-color: #00DB9C !important;
    color: rgba(0,0,0,1);
}

.card {
    h4 {
        font-size: 18px;
        font-weight: 700;
    }
    p {
        font-size: 16px;
        font-weight: 500;

        &.text-sm {
            font-size: 13px;
            font-weight: 500;
        }
    }
}

.building {
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url('assets/images/building-bg.png');
    background-repeat: no-repeat;
    background-position: 100% 100%;
}

.form-control-label {
    font-size: 15px;
    font-weight: 700;
}

.text-sm {
    font-size: 12px;
    font-weight: 300;
}

.avatar-container {
    position: relative;
    width: fit-content;
    left: 36%;
}

.image-container {
    height: 140px;
    width: 140px;
    z-index: 1;
    border-radius: 50%;
    background-color: #fff;
    background-position: center;
    background-size: cover;
    box-shadow: 0 6px 14px 14px rgba(0,0,0,0.04);
    overflow: hidden;
}

.icon-wrapper {
    label {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input[type='file'] {
        display: none;
    }
}

.icon-wrapper {
    position: absolute;
    height: 40px;
    width: 40px;
    padding: .35rem;
    background-color:#2962FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10%;
    top: 65%;
    z-index: 2;
    cursor: pointer;

    &:hover {
        transition: all .25s;
        background-color: darken(#2962FF, 15%);
    }

    .icon {
        color: white;
    }

    label {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input[type='file'] {
        display: none;
    }
}

.form-group.required .form-label:after {
    content:"*";
    color:red;
  }

.dropdown-toggle-alt::after {
    border: 0 none !important;
    margin-left: 0 !important;
    content: " ";
}

.dropdown-toggle::after {
    content: none;
}

.xng-breadcrumb-link {
    color: #0d6efd !important;
    text-decoration: underline !important;
    font-size: 0.85em !important;
}

.xng-breadcrumb-trail, .xng-breadcrumb-separator {
    font-size: 0.85em !important;
}

// .xng-breadcrumb-separator {
    // content: ">" !important;
// }

.fixed-bottom {
    position: fixed;
    bottom: 0;
}

.overflow-hidden {
    overflow: hidden;
}

.bg-info-300 {
    background-color: $cyan-300 !important;
}

.center-icon {
    position: absolute; top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%);
}

.badge {
    font-size: 0.75em !important;
    font-weight: 500 !important;
}

.hover-info {
    position: relative;
    background-color: white !important;
    color: $cyan-500 !important;
    &:hover {
        background-color: $cyan-500 !important;
        color: white !important;
        cursor: pointer;
    }
}

.hover-danger {
    background-color: white !important;
    color: $red-300 !important;
    &:hover {
        background-color: $red-500 !important;
        color: white !important;
        cursor: pointer;
    }

}

.w-33 {
    width: 33% !important;
}

@media (max-width: 992px) {
    .main-nav {
        width: 80px !important;

        .avatar {
            width: 24px;
            height: 24px;
            overflow: hidden;
        }
    }

    .nav-link, .btn {
        padding-left: 8px !important;
    }

    .fixed-bottom {
        width: 60px;
    }
}

@media (min-width: 993px) {
    .main-nav {
        width: 290px !important;

        .avatar {
            width: 44px;
            height: 44px;
            overflow: hidden;
        }

        .username {
            font-size: 1.1em !important;
            font-weight: 500;
        }

        .nav-item {
            h4 {
                font-size: 1.1em !important;
                font-weight: bold;
            }
        }

        .nav-link {
            .btn {
                font-size: 1.1em !important;
                font-weight: 400;
            }

            span {
                font-size: 1.1em !important;
                font-weight: 400;
            }

            .nav-icon {
                top: 2px;
                position: relative;
            }
        }
    }

    .fixed-bottom {
        width: 300px;
    }

    .logout {
        font-size: 1.0em !important;
        font-weight: 400;
    }
}

@media (min-width: 1480px) {
    .main-nav {
        width: 360px !important;
    }
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.list-group-item {
    h4 {
        font-size: 1.25em;
        font-weight: 700;
    }

    p {
        font-size: 0.9em;
    }
}

.form-check-input {
    clear: left;
  }
  
  .form-switch.form-switch-sm {
    margin-bottom: 0.5rem; /* JUST FOR STYLING PURPOSE */
  }
  
  .form-switch.form-switch-sm .form-check-input {
    height: 1rem;
    width: calc(1rem + 0.75rem);
    border-radius: 2rem;
  }
  
  .form-switch.form-switch-md {
    margin-bottom: 1rem; /* JUST FOR STYLING PURPOSE */
  }
  
  .form-switch.form-switch-md .form-check-input {
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem;
  }
  
  .form-switch.form-switch-lg {
    margin-bottom: 1.5rem; /* JUST FOR STYLING PURPOSE */
  }
  
  .form-switch.form-switch-lg {
    .form-check-input {
        height: 2rem;
        width: calc(3rem + 0.75rem);
        border-radius: 4rem;
    }

    .form-check-label {
        font-size: 1.2em;
        line-height: 2.2em;
    }
  }
  
  .form-switch.form-switch-xl {
    margin-bottom: 2rem; /* JUST FOR STYLING PURPOSE */
  }
  
  .form-switch.form-switch-xl .form-check-input {
    height: 2.5rem;
    width: calc(4rem + 0.75rem);
    border-radius: 5rem;
  }